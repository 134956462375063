import {Modal} from './Modal';
import styled from 'styled-components';

const StyledContent = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    max-width: 325px;
    max-height: 100%;
    overflow-y: auto;
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 24px;
`;

export default function ConfirmationModal({children, ...props}) {
    return (
        <Modal {...props}>
            <StyledContent>
                {children}
            </StyledContent>
        </Modal>
    );
}