import styled from 'styled-components';
import {Heading2, Heading5} from '../../../components/Heading/Heading';
import {ReactComponent as Info} from '../../../assets/icons/info.svg';
import {useOpenModal} from '../../../hooks/useOpenModal';
import ModuleModal from '../../../components/Modal/ModuleModal';
import {Text} from '../../../components/Text/Text';
import {Tag} from '../../../components/Tag/Tag';
import {AspectRatio} from '../../../components/AspectRatio/AspectRatio';

const StyledModuleDetailTopBar = styled.div`
    background-color: var(--color-primary);
    padding: 16px 24px;
`;

const Clickable = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
`;

const StyledInfo = styled(Info)`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    gap: 16px;
`;

const ImageWrapper = styled.div`
    position: relative;
    overflow: hidden;
    max-width: 210px;
    border-radius: 4px;
    margin-bottom: 16px;
`;

const ColorOverlay = styled.div`
    background: var(--color-image-overlay);
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
`;

const StyledTag = styled(Tag)`
    display: inline-block;
    margin-bottom: 16px;
`;

export const ModuleDetailTopBar = ({title, text, tag, image}) => {
    const {isOpen, handleOpen, handleClose} = useOpenModal();

    return (
        <>
            <StyledModuleDetailTopBar>
                <Clickable type="button" onClick={handleOpen}>
                    <Heading2 $white $noMargin $truncate>{title}</Heading2>
                    <StyledInfo />
                </Clickable>
            </StyledModuleDetailTopBar>

            <ModuleModal isOpen={isOpen} handleClose={handleClose}>
                {image &&
                    <ImageWrapper>
                        <AspectRatio src={image} alt="" />
                        <ColorOverlay />
                    </ImageWrapper>
                }
                {tag && <StyledTag>{tag}</StyledTag>}
                {title && <Heading5>{title}</Heading5>}
                {text && <Text $noMargin>{text}</Text>}
            </ModuleModal>
        </>
    );
}