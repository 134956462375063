import {Heading2} from '../../components/Heading/Heading';
import {useState} from 'react';
import {Accordion} from './Partials/Accordion';

export const AccordionBlock = ({data}) => {
    // If accordion canOpenAll, the state and handle function here are unused
    const [activeId, setActiveId] = useState(data?.panels?.filter(item => item?.preopened)[0]?.id || false);

    const handleClick = (id) => {
        setActiveId(activeId !== id ? id : false);
    }

    return (
        <section>
            {data?.title && <Heading2>{data.title}</Heading2>}

            {data?.panels?.map(item => (
                <Accordion
                    key={item.id}
                    title={item.title}
                    isActive={activeId === item.id}
                    handleClick={() => handleClick(item.id)}
                    canOpenAll={!Boolean(data.autoClose)}
                    isDefaultOpen={Boolean(item.preopened)}
                >
                    {item.text}
                </Accordion>
            ))}
        </section>
    );
}