import {createPortal} from 'react-dom';
import {Transition} from 'react-transition-group';
import {useEffect, useRef} from 'react';
import styled from 'styled-components';

const StyledModal = styled.div`
    pointer-events: none;
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    z-index: 100;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: 1; }
    &.entered { opacity: 1; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-modal-underlay);
    height: 100%;
    width: 100%;
    z-index: 100;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: .5; }
    &.entered { opacity: .5; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }   
`;

export const Modal = ({isOpen, handleClose, children}) => {
    const ref = useRef();
    const underlayRef = useRef(null);

    // Cleanup, needed for example when you open a modal and press the browser prev button, so that it allows you to scroll again
    useEffect(() => {
        return () => handleClose();
        //eslint-disable-next-line
    }, []);

    return createPortal(
        <>
            <Transition nodeRef={underlayRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <Underlay ref={underlayRef} className={state} onClick={handleClose} />
                )}
            </Transition>

            <Transition nodeRef={ref} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <StyledModal ref={ref} className={state}>
                        {children}
                    </StyledModal>
                )}
            </Transition>
        </>, document.body
    );
}