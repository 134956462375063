import {Outlet} from 'react-router-dom';
import {NavBar} from '../components/NavBar/NavBar';

const LoggedInLayout = () => {
    return (
        <>
            <NavBar />
            <Outlet />
        </>
    );
}

export default LoggedInLayout;