import styled from 'styled-components';
import {Text} from '../Text/Text';
import {ReactComponent as Show} from '../../assets/icons/password-show.svg';
import {ReactComponent as Hide} from '../../assets/icons/password-hide.svg';
import {useState} from 'react';

const StyledPasswordField = styled.div`
    margin-top: 40px;
`;

const LabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
`;

const Label = styled(Text).attrs({
    as: "label",
    $noMargin: true,
    $weight: "bold",
})`
    font-size: var(--fs-form-label);
    color: var(--color-black);
`;

const TogglePassword = styled(Text).attrs({
    as: "button",
    type: "button",
    $noMargin: true,
    $weight: "bold",
})`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: var(--fs-form-sublabel);
    color: var(--color-primary);
    border: none;
    background-color: transparent;
`;

const Icon = styled.svg`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

const Input = styled.input`
    width: 100%;
    min-height: 48px;
    padding: 13px 0 13px 14px;
    border-radius: 8px;
    color: var(--color-black);
    font-size: var(--fs-body);
    background-color: var(--color-white);
    appearance: none;
    outline: none;
    border: 1px solid var(--color-border);
    transition: border-color .15s ease, box-shadow .15s ease;
    
    &::placeholder {
        color: var(--color-grey);
        font-style: italic;
    }
    
    &:disabled {
        opacity: .75;
    }
    
    &:hover {
        border: 1px solid var(--color-primary);
    }
    
    &:focus {
        border: 1px solid var(--color-primary);
        box-shadow: 0 0 0 1px var(--color-primary), 0 0 0 4px rgba(var(--color-primary-rgb), .25);
    }
`;

export const PasswordField = ({className, name, label, placeholder}) => {
    const [type, setType] = useState("password");

    const handlePasswordVisibility = () => {
        setType(type === "password" ? "text" : "password");
    }

    return (
        <StyledPasswordField className={className}>
            <LabelWrapper>
                <Label htmlFor={name}>{label}</Label>
                <TogglePassword onClick={handlePasswordVisibility}>
                    <Icon as={type === "password" ? Show : Hide} />
                    {type === "password" ? "Toon wachtwoord" : "Verberg wachtwoord"}
                </TogglePassword>
            </LabelWrapper>

            <Input
                type={type}
                id={name}
                name={name}
                placeholder={placeholder}
                autoComplete="current-password"
                required
            />
        </StyledPasswordField>
    );
}