import {Navigate, Outlet, useLocation} from 'react-router-dom';
import Cookies from 'js-cookie';

export function RequireAuth() {
    const location = useLocation();

    if (Boolean(Cookies.get('da-vinci_token'))) {
        return <Outlet />
    }

    return <Navigate to="/login" state={{ from: location }} replace />;
}