import styled from 'styled-components';
import {useOpenModal} from '../../hooks/useOpenModal';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import ConfirmationModal from '../Modal/ConfirmationModal';
import {Heading3} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {Button} from '../Button/Button';
import {NavBarLink} from './NavBarLink';

const ButtonWrapper = styled.div`
    margin-top: 32px;
    justify-content: flex-end;
    display: flex;
    gap: 8px;
`;

export const LogoutButton = ({children}) => {
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const navigate = useNavigate();

    const handleLogout = () => {
        Cookies.remove('da-vinci_token');
        navigate("/login");
    }

    return (
        <>
            <NavBarLink as="button" type="button" onClick={handleOpen}>{children}</NavBarLink>

            <ConfirmationModal isOpen={isOpen} handleClose={handleClose}>
                <Heading3>Uitloggen</Heading3>
                <Text $noMargin>Weet je zeker dat je wil uitloggen?</Text>
                <ButtonWrapper>
                    <Button as="button" type="button" onClick={handleClose} $variant="secondary">Annuleren</Button>
                    <Button as="button" type="button" onClick={handleLogout}>Uitloggen</Button>
                </ButtonWrapper>
            </ConfirmationModal>
        </>
    );
}