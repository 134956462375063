import styled from 'styled-components';
import {Logo} from '../Logo/Logo';
import {CustomLink} from '../CustomLink/CustomLink';
import {LogoutButton} from './LogoutButton';
import {NavBarLink} from './NavBarLink';
import {ReactComponent as Modules} from '../../assets/icons/modules.svg';
import {ReactComponent as LogOut} from '../../assets/icons/logout.svg';

const StyledNavBar = styled.nav`
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-border);
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 72px; // Height is needed for sticky sidebar's height calculation
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
`;

const StyledCustomLink = styled(CustomLink)`
    display: inline-block;
`;

const StyledLogo = styled(Logo)`
    max-width: 100px;
    height: auto;
`;

const NavLinks = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const NavBar = () => {
    return (
        <StyledNavBar>
            <StyledCustomLink to="/">
                <StyledLogo />
            </StyledCustomLink>

            <NavLinks>
                <NavBarLink to="/"><Modules /> Modules</NavBarLink>
                <LogoutButton><LogOut /> Uitloggen</LogoutButton>
            </NavLinks>
        </StyledNavBar>
    );
}

