import {isRouteErrorResponse, Navigate, useLocation, useRouteError} from 'react-router-dom';
import Cookies from 'js-cookie';
import NoMatch from './NoMatch';
import styled from 'styled-components';
import {Button} from '../../components/Button/Button';
import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';

const StyledErrorBoundary = styled.div`
    padding: 24px;
    max-width: 525px;
`;

const ButtonWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    gap: 8px;
`;

const ErrorBoundary = () => {
    const error = useRouteError();
    const location = useLocation();

    let title = "Er is iets mis gegaan";
    let description = "Helaas lukt het ons niet om deze actie uit te voeren. Probeer het nog een keer, of op een later moment als dit niet lukt.";

    if(isRouteErrorResponse(error)) {
        switch (error.status) {
            case 401:
                Cookies.remove('da-vinci_token')
                return <Navigate to="/login" state={{ from: location }} replace />
            case 404:
                return <NoMatch />
            // Voorbeeld voor andere teksten bij andere http code
            // case 418:
            //     title = "418 - I'm a teapot"
            //     description = "Beschrijving"
            //     break;
            default:
                title = `${error.status} - Er is iets mis gegaan`;
        }
    }

    return (
        <StyledErrorBoundary>
            <Heading1>{title}</Heading1>
            <Text $noMargin>{description}</Text>
            <ButtonWrapper>
                <Button to={location?.pathname ?? "/"} reloadDocument>Probeer het opnieuw</Button>
                {/*<Button to="/login" $variant="secondary">Inloggen</Button>*/}
            </ButtonWrapper>
        </StyledErrorBoundary>
    );
}

export default ErrorBoundary;