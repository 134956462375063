import {useRef, useState} from 'react';

export const useOpenModal = () => {
    const touchActionStyle = useRef("");
    const overflowStyle = useRef("");
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
        touchActionStyle.current = document.body.style.touchAction;
        document.body.style.touchAction = "none";
        overflowStyle.current = document.body.style.overflow;
        document.body.style.overflow = "hidden";
    };

    const handleClose = () => {
        setIsOpen(false);
        document.body.style.touchAction = touchActionStyle.current;
        document.body.style.overflow = overflowStyle.current;
    };

    return {isOpen, handleOpen, handleClose};
}