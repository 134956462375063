import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import styled from 'styled-components';
import {Button} from '../../components/Button/Button';
import {Logo} from '../../components/Logo/Logo';
import {CustomLink} from '../../components/CustomLink/CustomLink';

const StyledNoMatch = styled.div`
    padding: 24px;
    max-width: 525px;
`;

const StyledCustomLink = styled(CustomLink)`
    display: inline-block;
`;

const StyledLogo = styled(Logo)`
    margin-bottom: 40px;
    max-width: 120px;
    height: auto;
`;

const ButtonWrapper = styled.div`
    margin-top: 40px;
`;

const NoMatch = () => {
    return (
        <StyledNoMatch>
            <StyledCustomLink to="/">
                <StyledLogo />
            </StyledCustomLink>
            <Heading1>404 - Pagina niet gevonden</Heading1>
            <Text $noMargin>Sorry, we konden deze pagina niet vinden. Het lijkt erop dat deze pagina niet (meer) bestaat, of tijdelijk niet beschikbaar is.</Text>
            <ButtonWrapper>
                <Button to="/">Inloggen</Button>
            </ButtonWrapper>
        </StyledNoMatch>
    )
}

export default NoMatch;