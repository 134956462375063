import styled from 'styled-components';

const Outer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: ${({$ratioWidth}) => $ratioWidth} / ${({$ratioHeight}) => $ratioHeight};
    background-color: var(--color-grey-10);
    
    @supports not (aspect-ratio: 16 / 9) {
        overflow: hidden;
        padding-top: ${({$fallback}) => $fallback}%;
    }
`;

const Inner = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    
    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        inset: 0;
    }
`;

export const AspectRatio = ({className, ratioWidth = 16, ratioHeight = 9, ...props}) => {
    const fallback = ratioHeight / ratioWidth * 100;

    return (
        <Outer className={className} $ratioWidth={ratioWidth} $ratioHeight={ratioHeight} $fallback={fallback}>
            <Inner {...props} />
        </Outer>
    );
}