import styled, {css} from 'styled-components';
import {CustomLink} from '../CustomLink/CustomLink';

export const buttonVariants = (variant = "primary") => ({
    primary: css`
        color: var(--color-white);
        background-color: var(--color-primary);
        border: 1px solid var(--color-primary);
        border-bottom: 4px solid var(--color-primary-60);

        &:hover {
            background-color: var(--color-primary-60);
            border: 1px solid var(--color-primary-60);
            border-bottom: 4px solid var(--color-primary-70);
        }
        
        &:active {
            margin-top: 3px;
            border: 1px solid var(--color-primary-60);
        }
    `,
    secondary: css`
        color: var(--color-primary);
        background-color: var(--color-white);
        border: 1px solid var(--color-border);
        border-bottom: 4px solid var(--color-border);
        
        &:hover {
            background-color: var(--color-grey-10);
            border: 1px solid var(--color-grey-40);
            border-bottom: 4px solid var(--color-grey-40);
        }

        &:active {
            margin-top: 3px;
            border: 1px solid var(--color-grey-40);
        }
    `,
}[variant]);

const StyledButton = styled(CustomLink)`
    ${({$variant}) => buttonVariants($variant)};
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    padding: ${({$large}) => $large ? '12px 24px' : '12px 16px'};
    font-family: var(--font-alt);
    font-weight: var(--fw-bold);
    font-size: ${({$large}) => $large ? 'var(--fs-button-large)' : 'var(--fs-button)'};
    text-decoration: none;
    line-height: 1.25;
    text-align: center;
    max-width: fit-content;
    
    &:disabled {
        opacity: .7;
        pointer-events: none;
    }
`;

export const Button = ({className, children, to, ...props}) => {
    return (
        <StyledButton className={className} to={to} {...props}>
            {children}
        </StyledButton>
    );
}