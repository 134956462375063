import styled, {css} from 'styled-components';
import {Heading5} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {Button} from '../Button/Button';
import {AspectRatio} from '../AspectRatio/AspectRatio';
import {LayeredCustomLink} from '../CustomLink/CustomLink';
import {layeredLinkMixin} from '../Utils/Mixins';
import {Tag} from '../Tag/Tag';

const StyledCard = styled.article`
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-card);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    ${layeredLinkMixin};
`;

const ImageWrapper = styled.div`
    position: relative;
    overflow: hidden;
`;

const StyledAspectRatio = styled(AspectRatio)`
    transition: transform .15s ease;
    // This condition doesn't work, it looks like a styled-components bug?
    ${({$isClickable}) => $isClickable && css`
        ${StyledCard}:hover & {
            transform: scale(1.05);
        }
    `};
`;

const StyledTag = styled(Tag)`
    position: absolute;
    top: 16px;
    right: 16px;
`;

const ColorOverlay = styled.div`
    background: var(--color-image-overlay);
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
`;

const InnerCard = styled.div`
    flex-grow: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    margin-top: auto;
    text-align: right;
`;

const InnerButtonWrapper = styled.div`
    padding-top: 24px;
`;

export const Card = ({title, text, buttonVariant, buttonLabel, buttonType, buttonLink, image, tag, truncateTitle}) => {
    const isClickable = (buttonLabel && buttonType && buttonLink);

    return (
        <StyledCard title={title}>
            {isClickable && <LayeredCustomLink to={buttonLink} type={buttonType} />}
            {image &&
                <ImageWrapper>
                    <StyledAspectRatio src={image} alt="" $isClickable={isClickable}/>
                    <ColorOverlay />
                    {tag && <StyledTag>{tag}</StyledTag>}
                </ImageWrapper>
            }
            <InnerCard>
                {title && <Heading5 $truncate={truncateTitle}>{title}</Heading5>}
                {text && <Text $truncate $clamp={2} $noMargin>{text}</Text>}
                {isClickable &&
                    <ButtonWrapper>
                        <InnerButtonWrapper>
                            <Button to={buttonLink} type={buttonType} $variant={buttonVariant}>{buttonLabel}</Button>
                        </InnerButtonWrapper>
                    </ButtonWrapper>
                }
            </InnerCard>
        </StyledCard>
    );
}