import styled from 'styled-components';
import {Helmet} from 'react-helmet-async';
import {Heading1, Heading3} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {Container} from '../../components/Utils/Utils';
import {Page} from '../../components/Page/Page';
import {Card} from '../../components/Card/Card';
import {useEffect, useRef, useState} from 'react';
import {useLoaderData, useLocation, useSearchParams} from 'react-router-dom';
import {Empty} from '../../components/Empty/Empty';
import {fetchData} from '../../api/fetch';
import placeholder from '../../assets/placeholder.jpg';

const StyledModules = styled.div`
    margin-top: 72px;
`;

const Header = styled.div`
    max-width: 500px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const Tabs = styled.div`
    position: relative;
    display: flex;
    gap: 20px;
    margin-bottom: 32px;
`;

const Tab = styled.button.attrs({
    type: "button",
})`
    color: var(--color-primary);
    font-family: var(--font-alt);
    font-weight: var(--fw-bold);
    font-size: var(--fs-button-large);
    background: transparent;
    border: none;
    padding: 0 0 8px 0;
    cursor: pointer;
`;

const Underline = styled.div`
    position: absolute;
    left: ${({$left}) => $left ? `${$left}px` : 0};
    bottom: 0;
    width: ${({$width}) => $width ? `${$width}px` : '82px'}; // Initial size is width of first item, to prevent flash
    height: 3px;
    pointer-events: none;
    background-color: var(--color-primary);
    border-radius: 9999px;
    transition: width .3s cubic-bezier(.4, 0, .2, 1), left .3s cubic-bezier(.4, 0, .2, 1);
`;

const Year = styled.div`
    margin-bottom: 56px;
    
    &:last-of-type {
        margin-bottom: 112px;
    }
`;

const Cards = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
`;

export async function modulesLoader({request}) {
    const moduleData = await fetchData(request, 'module-page');
    return {moduleData};
}

export function modulesShouldRevalidate({currentUrl, nextUrl, defaultShouldRevalidate}) {
    // Do not revalidate when only changing search params
    if(currentUrl?.search !== nextUrl?.search) return false;
    return defaultShouldRevalidate;
}

const Modules = () => {
    const {moduleData} = useLoaderData();

    let [searchParams, setSearchParams] = useSearchParams();
    const {search} = useLocation();
    const tabsRef = useRef([]);

    const [activeTabIndex, setActiveTabIndex] = useState(getActiveTabIndex());
    const [activeTabUnderlineWidth, setActiveTabUnderlineWidth] = useState(0)
    const [activeTabUnderlineLeft, setActiveTabUnderlineLeft] = useState(0);

    // When navigating with prev/next buttons, the search params change, so it should update the activeTabIndex
    useEffect(() => {
        setActiveTabIndex(getActiveTabIndex());
        //eslint-disable-next-line
    }, [search])

    // When the activeTabIndex changes, setTabPosition
    useEffect(() => {
        if (activeTabIndex === null) return;

        const currentTab = tabsRef?.current[activeTabIndex];
        setActiveTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
        setActiveTabUnderlineWidth(currentTab?.clientWidth ?? 0);

        const tabSearchParam = parseInt(searchParams.get("tab"));
        if((activeTabIndex === 0 && tabSearchParam)) setSearchParams({}, { preventScrollReset: true });
        if((activeTabIndex > 0) && (activeTabIndex !== tabSearchParam)) setSearchParams({"tab": activeTabIndex}, { preventScrollReset: true });
        //eslint-disable-next-line
    }, [activeTabIndex]);

    function getActiveTabIndex() {
        const parsedTabSearchParam = parseInt(searchParams.get("tab"));
        return (!parsedTabSearchParam || parsedTabSearchParam > moduleData?.years?.length) ? 0 : parsedTabSearchParam;
    }

    const currentData = activeTabIndex === 0 ? moduleData?.years : [moduleData?.years[activeTabIndex - 1]];

    return (
        <>
            <Helmet>
                <title>Da Vinci - {moduleData?.title}</title>
            </Helmet>

            <Page>
                <Page.Main>
                    <StyledModules>
                        <Container>
                            <Header>
                                <Heading1 $noMargin>{moduleData?.title}</Heading1>
                                <Text $noMargin>{moduleData?.text}</Text>
                            </Header>

                            <Tabs>
                                <Tab onClick={() => setActiveTabIndex(0)}>
                                    Alle jaren
                                </Tab>
                                {moduleData?.years?.map((tab, index) => (
                                    <Tab
                                        key={tab.id}
                                        ref={(el) => (tabsRef.current[index + 1] = el)}
                                        onClick={() => setActiveTabIndex(index + 1)}
                                    >
                                        {tab.title}
                                    </Tab>
                                ))}
                                <Underline $left={activeTabUnderlineLeft} $width={activeTabUnderlineWidth} />
                            </Tabs>

                            {currentData?.map(item => (
                                <Year key={item.id}>
                                    <Heading3>{item.title}</Heading3>
                                    {item?.modules?.length > 0 ? (
                                        <Cards>
                                            {item?.modules?.map(moduleBlock => (
                                                <Card
                                                    key={moduleBlock.id}
                                                    title={moduleBlock.title}
                                                    text={moduleBlock.text}
                                                    buttonVariant="primary"
                                                    buttonLabel="Selecteer module"
                                                    buttonLink={moduleBlock.url}
                                                    buttonType="internal"
                                                    image={moduleBlock.image || placeholder}
                                                    tag={item.title}
                                                    truncateTitle={true}
                                                />
                                            ))}
                                        </Cards>
                                    ) : (
                                        <Empty />
                                    )}
                                </Year>
                            ))}
                        </Container>
                    </StyledModules>
                </Page.Main>
            </Page>
        </>
    );
}

export default Modules;