import styled from 'styled-components';
import logo from '../../assets/logo.png';

export const Logo = styled.img.attrs({
    src: logo,
    alt: ""
})`
    display: block;
    object-fit: cover;
    width: 182px;
    height: 83px;
`;