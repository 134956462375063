import {css} from 'styled-components';

export const truncateMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ $clamp = 1 }) => `-webkit-line-clamp: ${$clamp};`}
`;

export const layeredLinkMixin = css`
    a {
        position: relative;
        z-index: 1;
    }
`;