import Cookies from "js-cookie";

export const fetchData = (request, endpoint) => {
    const accessToken = Cookies.get('da-vinci_token');

    return fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
        ...(Boolean(accessToken) && {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }),
        signal: request.signal,
    }).then((res) => {
        if (!res.ok) throw new Response("", { status: res.status || 400 });

        return res.json();
    }).catch((error) => {
        if (error?.name === "AbortError") return;

        if(error?.status === 401 || error?.response?.status === 401) {
            Cookies.remove('da-vinci_token')
        }

        throw new Response("", { status: error.status || 400 });
    });
};