import styled, {css} from 'styled-components';
import {truncateMixin} from '../Utils/Mixins';
import {headingStyles} from '../Heading/Heading';

export const textStyles = css`
    color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-secondary)'};
    font-size: ${({$small}) => $small ? 'var(--fs-body-small)' : 'var(--fs-body)'};
    line-height: 1.35;
    margin: ${({$noMargin}) => $noMargin ? '0' : '0 0 16px 0'};
    overflow-wrap: anywhere;
    text-align: ${({$align}) => $align === "center" ? "center" : "left"};
    ${({$italic}) => $italic && 'font-style: italic;'};
    ${({$weight}) => $weight && `font-weight: var(--fw-${$weight});`};
    ${({ $truncate }) => $truncate && truncateMixin};
    
    a {
        color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-primary)'};
        text-decoration: underline;
        font-weight: var(--fw-bold);
        
        &:hover {
            color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-primary-60)'};
            text-decoration: none;
        }
    }
`;

export const Text = styled.p`
    ${textStyles};
`;

export const HTMLText = styled.div`
    p, blockquote, ul, ol {
        ${textStyles};
    }

    strong { font-weight: var(--fw-bold); }
    em { font-style: italic; }
    u { text-decoration: underline; }
    ul { padding-left: 20px; }
    ol { padding-left: 20px; }
    
    h2, h3, h4, h5, h6 {
        ${headingStyles};
        margin: 0 0 4px 0;
    }
    
    h2 { font-size: var(--fs-heading-2); }
    h3 { font-size: var(--fs-heading-3); }
    h4 { font-size: var(--fs-heading-4); }
    h5 { font-size: var(--fs-heading-5); }
    h6 { font-size: var(--fs-heading-6); }

    blockquote {
        background: ${({$white}) => $white ? "var(--color-white)" : "var(--color-primary)"};
        border-radius: 4px;
        color: ${({$white}) => $white ? "var(--color-primary)" : "var(--color-white)"};
        padding: 16px;
    }

    blockquote > * {
        color: ${({$white}) => $white ? "var(--color-primary)" : "var(--color-white)"};
    }

    blockquote > *:last-child {
        margin-bottom: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;