import styled from 'styled-components';
import {Heading2} from '../../components/Heading/Heading';
import {AspectRatio} from '../../components/AspectRatio/AspectRatio';
import {Text} from '../../components/Text/Text';

const aspectRatios = [
    {
        key: "sixteen-by-nine",
        ratioWidth: 16,
        ratioHeight: 9,
    },
    {
        key: "four-by-three",
        ratioWidth: 4,
        ratioHeight: 3,
    },
    {
        key: "one-by-one",
        ratioWidth: 1,
        ratioHeight: 1,
    }
];

const ImageWrapper = styled.div`
    ${({$imageSize}) => $imageSize === "auto-size" && "max-width: fit-content;"};
`;

const Wrapper = styled.div`
    iframe {
        width: 100%;
        height: 100%;
    }
`;

const Image = styled.img`
    display: block;
    width: ${({$imageSize}) => $imageSize === "auto-size" ? "auto" : "100%"};
    max-width: 100%;
    height: auto;
    object-fit: cover;
`;

const Caption = styled(Text).attrs({
    $small: true,
    $noMargin: true,
    $align: "center",
    $italic: true,
})`
    margin-top: 8px;
`;

export const MediaBlock = ({data}) => {
    const aspectRatio = aspectRatios?.find(item => item?.key === data?.aspectRatio);

    return (
        <section>
            {data?.title && <Heading2>{data.title}</Heading2>}

            {(data?.mediaType === "image" && data?.image) ? (
                <ImageWrapper $imageSize={data?.imageSize}>
                    {data?.imageSize === "aspect-ratio" ? (
                        <AspectRatio
                            src={data.image}
                            alt=""
                            ratioWidth={aspectRatio?.ratioWidth}
                            ratioHeight={aspectRatio?.ratioHeight}
                        />
                    ) : (
                        <Image src={data?.image} alt="" $imageSize={data?.imageSize} />
                    )}
                    {data?.caption && <Caption>{data.caption}</Caption>}
                </ImageWrapper>
            ) : (data?.mediaType === "iframe" && data?.iFrame) ? (
                <Wrapper>
                    <AspectRatio
                        as="div"
                        dangerouslySetInnerHTML={{__html: data.iFrame}}
                        ratioWidth={aspectRatio?.ratioWidth}
                        ratioHeight={aspectRatio?.ratioHeight}
                    />

                    {data?.caption && <Caption>{data.caption}</Caption>}
                </Wrapper>
            ) : (data?.mediaType === "embed" && data?.embed) ? (
                <Wrapper>
                    <AspectRatio
                        as="iframe"
                        src={data.embed}
                        ratioWidth={aspectRatio?.ratioWidth}
                        ratioHeight={aspectRatio?.ratioHeight}
                    />

                    {data?.caption && <Caption>{data.caption}</Caption>}
                </Wrapper>
            ) : ""}
        </section>
    );
}
