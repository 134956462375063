import styled, {css} from 'styled-components';
import {truncateMixin} from '../Utils/Mixins';

export const headingStyles = css`
    color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-secondary)'};
    font-family: var(--font-alt);
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-1);
    line-height: 1.35;
    margin: ${({$noMargin}) => $noMargin ? '0' : '0 0 16px 0'};
    overflow-wrap: anywhere;
    text-align: ${({$align}) => $align === "center" ? "center" : "left"};
    ${({ $truncate }) => $truncate && truncateMixin};
`;

export const Heading1 = styled.h1`
    ${headingStyles};
`;

export const Heading2 = styled(Heading1).attrs({
    as: "h2"
})`
    font-size: var(--fs-heading-2);
`;

export const Heading3 = styled(Heading1).attrs({
    as: "h3"
})`
    font-size: var(--fs-heading-3);
    margin: ${({$noMargin}) => $noMargin ? '0' : '0 0 12px 0'};
`;

export const Heading4 = styled(Heading1).attrs({
    as: "h4"
})`
    font-size: var(--fs-heading-4);
    margin: ${({$noMargin}) => $noMargin ? '0' : '0 0 8px 0'};
`;

export const Heading5 = styled(Heading1).attrs({
    as: "h5"
})`
    font-size: var(--fs-heading-5);
    margin: ${({$noMargin}) => $noMargin ? '0' : '0 0 4px 0'};
`;

export const Heading6 = styled(Heading1).attrs({
    as: "h6"
})`
    font-size: var(--fs-heading-6);
    margin: ${({$noMargin}) => $noMargin ? '0' : '0 0 4px 0'};
`;