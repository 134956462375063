import {createGlobalStyle} from "styled-components";
import normalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`
    ${normalize}
    
    * { 
        box-sizing: border-box;
    }

    :root {
        // Primitives
        --color-white: #fff;
        --color-black: #000;
        --color-background: #F2F7F8;
        
        --color-primary: #00b3ff;
        --color-primary-rgb: 0, 179, 255;
        --color-primary-60: #009FE3;
        --color-primary-70: #0192D0;
        
        --color-secondary: #004e77;
        --color-secondary-rgb: 0, 78, 119;

        --color-grey-10: #f6fcff;
        --color-grey-15: #f5f5f5;
        --color-grey-20: #e7eef1;
        --color-grey-30: #d5dfe4;
        --color-grey-40: #c1d7e3;
        --color-grey: #c0cfd5;
        
        --color-error: #e64949;
        
        // Tokens
        --color-slider-overlay: rgba(var(--color-secondary-rgb), .3);
        --color-footer-text: var(--color-grey);
        --color-border: var(--color-grey-30);
        --color-modal-underlay: var(--color-secondary);
        --color-accordion-active-background: var(--color-grey-20);
        --color-table-header-background: var(--color-grey-20);
        --color-tab-background: var(--color-grey-20);
        --color-tag-primary: #E75892;
        --color-tag-secondary: #4675B4;
        --color-tag: linear-gradient(90deg, var(--color-tag-primary) 0%, var(--color-tag-secondary) 60%);
        --color-image-overlay: linear-gradient(180deg, rgba(var(--color-secondary-rgb), 0) 0%, rgba(var(--color-secondary-rgb), .5) 100%);
        
        // Font properties
        --font: 'Lato', sans-serif;
        --font-alt: 'Bitter', serif;
        --fw-regular: 400;
        --fw-medium: 500;
        --fw-semi-bold: 600;
        --fw-bold: 700;
        --fw-extra-bold: 800;
        
        // Font sizes
        --fs-heading-1: 3.2rem;
        --fs-heading-1-mobile: 2.4rem;
        --fs-heading-2: 2.8rem;
        --fs-heading-3: 2.4rem;
        --fs-heading-4: 2.0rem;
        --fs-heading-5: 1.8rem;
        --fs-heading-6: 1.6rem;
        
        --fs-body: 1.6rem;
        --fs-body-small: 1.4rem;
        --fs-button-large: 1.8rem;
        --fs-button: 1.6rem;
        --fs-navlink: 1.6rem;
        
        --fs-form-label: 1.6rem;
        --fs-form-sublabel: 1.4rem;
        --fs-footer-text: 1.6rem;
        --fs-tag: 1.4rem;
        --fs-tooltip-label: 1.2rem;

        // Box shadow
        --box-shadow-card: 0 4px 0 0 var(--color-grey-20); 
    }

    html, body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
        padding: 0;
        font-size: 62.5%;
        font-family: var(--font), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        background-color: var(--color-background);
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        tab-size: 4;
        font-feature-settings: normal;
    }

    // NProgress navigation loader bar
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-primary);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
    }
`;

export default GlobalStyle;