import styled from 'styled-components';
import {Heading1} from '../../../components/Heading/Heading';
import {Text} from '../../../components/Text/Text';
import {Logo} from '../../../components/Logo/Logo';

const StyledPreventSmallScreens = styled.div`
    padding: 16px;
    background-color: var(--color-primary);
    min-height: 100vh;
`;

const Inner = styled.div`
    background-color: var(--color-white);
    padding: 32px 24px;
    border-radius: 8px;
`;

const StyledLogo = styled(Logo)`
    margin-bottom: 32px;
    max-width: 108px;
    height: auto;
`;

const StyledHeading1 = styled(Heading1)`
    color: var(--color-primary);
    font-size: var(--fs-heading-1-mobile);
    margin-bottom: 8px;
`;

const StyledText = styled(Text)`
    color: var(--color-black);
`;

export const PreventSmallScreens = () => {
    return (
        <StyledPreventSmallScreens>
            <Inner>
                <StyledLogo />
                <StyledHeading1>De website werkt alleen op een groter scherm</StyledHeading1>
                <StyledText $noMargin>De website werkt helaas niet op dit apparaat, omdat de schermgrootte te klein is. Probeer het nog eens op een apparaat met een groter scherm, zoals een laptop of een desktop computer.</StyledText>
            </Inner>
        </StyledPreventSmallScreens>
    )
}