import styled from 'styled-components';
import {TextBlock} from '../../blocks/TextBlock/TextBlock';
import {AccordionBlock} from '../../blocks/AccordionBlock/AccordionBlock';
import {MediaBlock} from '../../blocks/MediaBlock/MediaBlock';
import {TabBlock} from '../../blocks/TabBlock/TabBlock';
import {TableBlock} from '../../blocks/TableBlock/TableBlock';

const blockComponents = {
    "accordion": AccordionBlock,
    "media": MediaBlock,
    "tabs": TabBlock,
    "table": TableBlock,
    "text": TextBlock,
}

// Display a block individually
const Block = ({ blockData }) => {
    // Prepare the component
    const BlockComponent = blockComponents[blockData.type];

    if (!BlockComponent) return null;

    // Display the block
    return <BlockComponent data={blockData} />
}

const StyledSection = styled.section`
    padding-top: ${({$spaceAbove}) => $spaceAbove ? "60px" : "0"};
    padding-bottom: ${({$spaceBelow}) => $spaceBelow ? "60px" : "0"};
    display: grid;
    grid-template-columns: repeat(${({$columns}) => $columns}, 1fr);
    gap: 24px;
`;

// Display the list of sections
export const Sections = ({data}) => {
    if(!data) return null;

    return (
        <>
            {data.map(section => (
                <StyledSection
                    key={section.id}
                    $spaceAbove={section?.spaceAbove}
                    $spaceBelow={section?.spaceBelow}
                    $columns={section?.columns ?? 1}
                >
                    {section?.blocks?.map(block => (
                        <Block key={block.id} blockData={block} />
                    ))}
                </StyledSection>
            ))}
        </>
    )
}