import styled from 'styled-components';
import {Text} from '../Text/Text';

const StyledFooterText = styled(Text).attrs({
    $noMargin: true,
    $align: "center"
})`
    color: var(--color-footer-text);
    font-size: var(--fs-footer-text);
`;

export const FooterText = ({className}) => {
    return (
        <StyledFooterText className={className}>
            &copy; {new Date().getFullYear()} Da Vinci College Leiden
        </StyledFooterText>
    );
}