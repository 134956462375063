import styled, {css} from 'styled-components';
import {HTMLText} from '../../components/Text/Text';
import {Heading2} from '../../components/Heading/Heading';
import {Button} from '../../components/Button/Button';

const StyledTextBlock = styled.section`
    ${({$backgroundColor}) => ($backgroundColor === "white" || $backgroundColor === "blue") && css`
        padding: 32px;
        border-radius: 4px;
        background-color: ${$backgroundColor === "white" ? "var(--color-white)" : "var(--color-primary)"};
    `};
`;

const ButtonWrapper = styled.div`
    margin-top: 40px;
`;

export const TextBlock = ({data}) => {
    const hasButton = (data?.linkLabel && data?.linkType && data?.link);

    return (
        <StyledTextBlock $backgroundColor={data?.backgroundColor}>
            {data?.title && <Heading2 $white={data?.backgroundColor === "blue"}>{data.title}</Heading2>}
            {data?.text && (
                <HTMLText
                    dangerouslySetInnerHTML={{__html: data.text}}
                    $white={data?.backgroundColor === "blue"}
                />
            )}
            {hasButton && (
                <ButtonWrapper>
                    <Button to={data.link} type={data.linkType} $variant={data?.backgroundColor === "blue" ? "secondary" : "primary"}>
                        {data.linkLabel}
                    </Button>
                </ButtonWrapper>
            )}
        </StyledTextBlock>
    );
}
