import styled from 'styled-components';
import {Helmet} from 'react-helmet-async';
import {Heading1} from '../../components/Heading/Heading';
import {Container} from '../../components/Utils/Utils';
import {Page} from '../../components/Page/Page';
import {ModuleDetailTopBar} from './Partials/ModuleDetailTopBar';
import {PageNavigation} from '../../components/PageNavigation/PageNavigation';
import {fetchData} from '../../api/fetch';
import {Sections} from '../../components/Sections/Sections';
import {useLoaderData} from 'react-router-dom';
import {Card} from '../../components/Card/Card';
import {Empty} from '../../components/Empty/Empty';

const StyledModuleDetail = styled.div``;

const InnerModuleDetail = styled.div`
    margin-top: 72px;
    margin-bottom: 32px;
`;

const Grid = styled.div`
    margin-top: 56px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    
    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export async function moduleDetailLoader({request, params}) {
    const parts = params["*"].split('/');
    const navigationUrl = parts.slice(0, 2).join('/') + '/';
    const navigationPromise = fetchData(request, `dynamic-page?url=${navigationUrl}`);
    const moduleDetailPromise = fetchData(request, `dynamic-page?url=${params["*"]}`);
    const [navigationData, moduleDetailData] = await Promise.all([navigationPromise, moduleDetailPromise]);
    return { navigationData, moduleDetailData };
}

const ModuleDetail = () => {
    const {navigationData, moduleDetailData} = useLoaderData();

    return (
        <>
            <Helmet>
                <title>Da Vinci - {moduleDetailData?.title}</title>
            </Helmet>

            <Page>
                <Page.SideBar data={navigationData} />

                <Page.Main>
                    <StyledModuleDetail>
                        <ModuleDetailTopBar
                            title={navigationData?.title}
                            text={navigationData?.text}
                            image={navigationData?.image}
                            tag={navigationData?.tag}
                        />

                        <Container>
                            <InnerModuleDetail>
                                <Heading1>{moduleDetailData?.title}</Heading1>

                                {moduleDetailData?.type === "overview-page" ? (
                                    moduleDetailData?.children?.length > 0 ? (
                                        <Grid>
                                            {moduleDetailData?.children?.map(item => (
                                                <Card
                                                    key={item.id}
                                                    title={item.title}
                                                    buttonVariant="secondary"
                                                    buttonLabel="Ga naar paragraaf"
                                                    buttonLink={item.url}
                                                    buttonType="internal"
                                                    truncateTitle={false}
                                                />
                                            ))}
                                        </Grid>
                                    ) : (
                                        <Empty
                                            title="Geen paragrafen gevonden"
                                            text="Er zijn (nog) geen paragrafen gevonden. Kijk op een later moment nog eens."
                                        />
                                    )
                                ) : moduleDetailData?.type === "dynamic-page" ? (
                                    moduleDetailData?.sections?.length > 0 ? (
                                        <Sections data={moduleDetailData?.sections} />
                                    ) : (
                                        <Empty
                                            title="Geen secties gevonden"
                                            text="Er zijn (nog) geen secties gevonden. Kijk op een later moment nog eens."
                                        />
                                    )
                                ) : ""}

                                <PageNavigation prevUrl={moduleDetailData?.previousUrl} nextUrl={moduleDetailData?.nextUrl} />
                            </InnerModuleDetail>
                        </Container>
                    </StyledModuleDetail>
                </Page.Main>
            </Page>
        </>
    );
}

export default ModuleDetail;