import styled from 'styled-components';
import {ReactComponent as EmptyIcon} from '../../assets/empty.svg';
import {Heading6} from '../Heading/Heading';
import {Text} from '../Text/Text';

const StyledEmpty = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 280px;
    margin: 24px auto 0 auto;
`;

const StyledEmptyIcon = styled(EmptyIcon)`
    margin-bottom: 16px;
`;

export const Empty = ({title = "Geen modules gevonden", text = "Er zijn (nog) geen modules gevonden. Kijk op een later moment nog eens."}) => {
    return (
        <StyledEmpty>
            <StyledEmptyIcon />
            <Heading6 $align="center">{title}</Heading6>
            <Text $align="center" $noMargin>{text}</Text>
        </StyledEmpty>
    );
}