import {Link, NavLink} from 'react-router-dom';
import styled from 'styled-components';

export const CustomLink = ({className, children, to, type, ...props}) => {
    const isExternalLink = type === "external";

    return (
        <Link
            className={className}
            to={to ?? "/"}
            target={isExternalLink ? "_blank" : "_self"}
            rel={isExternalLink ? "noreferrer" : undefined}
            {...props}
        >
            {children}
        </Link>
    );
}

export const LayeredCustomLink = styled(CustomLink)`
    position: static !important;

    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const CustomNavLink = ({className, children, to, ...props}) => {
    return (
        <NavLink
            className={className}
            to={to ?? "/"}
            {...props}
        >
            {children}
        </NavLink>
    );
}