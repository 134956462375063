import {Button} from '../../components/Button/Button';
import styled from 'styled-components';
import {Heading1} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {LoginSwiper} from './Partials/LoginSwiper';
import {FooterText} from '../../components/FooterText/FooterText';
import {PasswordField} from '../../components/PasswordField/PasswordField';
import {Helmet} from 'react-helmet-async';
import {Logo} from '../../components/Logo/Logo';
import Cookies from 'js-cookie';
import {useState} from "react";
import {useLoaderData, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {fetchData} from "../../api/fetch";
import {Statuses} from '../../constants/enums';

const StyledLogin = styled.div`
    display: flex;
`;

const AuthBox = styled.div`
    flex: 0 1 600px;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-right: 1px solid var(--color-border);
    padding: 48px 48px 32px 48px;
`;

const StyledHeading1 = styled(Heading1)`
    color: var(--color-primary);
    margin-top: 64px;
`;

const StyledText = styled(Text)`
    color: var(--color-black);
`;

const ButtonWrapper = styled.div`
    text-align: right;
    margin-top: 32px;
`;

const StyledFooterText = styled(FooterText)`
    margin-top: auto;
`;

const Error = styled(Text).attrs({
    $noMargin: true,
})`
    margin-top: 10px;
    color: var(--color-error);
`;

export async function loginLoader({request}) {
    const loginData = await fetchData(request, 'login-page');
    return {loginData};
}

const Login = () => {
    const {loginData} = useLoaderData();

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();
    const isCMSPreview = searchParams.get("CMSPreview") === "1";

    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);
    const disabled = status === Statuses.SUBMITTING;

    const handleSubmit = async (e) => {
        e.preventDefault();

        setStatus(Statuses.SUBMITTING);
        setError(false);

        const form = e.target;
        const body = JSON.stringify({
            "password": form.password.value
        });

        const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            return res.json();
        }).catch((err) => {
            return {"error": err};
        });

        if (data?.error) {
            setStatus(Statuses.IDLE);
            switch (data?.error) {
                case "Password is incorrect":
                    return setError({"field": "password", "message": "Het ingevulde wachtwoord is onjuist"});
                case "Password is required":
                    return setError({"field": "password", "message": "Zorg ervoor dat je een wachtwoord invult"});
                default:
                    return setError({"field": "password", "message": "Er is iets fout gegaan, probeer het opnieuw"});
            }
        }

        Cookies.set('da-vinci_token', data.accessToken, {
            expires: new Date(data.tokenExpires),
            secure: true,
            sameSite: 'none'
        });

        let redirectUrl = location.state?.from?.pathname || '/';
        if(isCMSPreview) redirectUrl = `${redirectUrl}?CMSPreview=1`;

        navigate(redirectUrl);
    }

    return (
        <>
            <Helmet>
                <title>Da Vinci - Inloggen</title>
            </Helmet>

            <StyledLogin>
                <AuthBox>
                    <Logo />

                    <StyledHeading1>{loginData?.title}</StyledHeading1>
                    <StyledText>{loginData?.text}</StyledText>
                    <StyledText $noMargin>Het wachtwoord is voor het laatst gewijzigd op: <strong>{loginData?.passwordLastEdited}</strong></StyledText>

                    <form method="post" onSubmit={handleSubmit}>
                        <PasswordField
                            name="password"
                            label="Wachtwoord"
                            placeholder="Wachtwoord"
                        />
                        {error && <Error>{error?.message}</Error>}

                        <ButtonWrapper>
                            <Button as="button" type="submit" disabled={disabled} $large>Inloggen</Button>
                        </ButtonWrapper>
                    </form>

                    <StyledFooterText />
                </AuthBox>

                <LoginSwiper images={loginData?.images} />
            </StyledLogin>
        </>
    );
}

export default Login;