import {Modal} from './Modal';
import styled from 'styled-components';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';

const StyledContent = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    max-width: 600px;
    max-height: 100%;
    overflow-y: auto;
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 24px;
`;

const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

export default function ModuleModal({children, ...props}) {
    return (
        <Modal {...props}>
            <StyledContent>
                <StyledCloseIcon onClick={props?.handleClose} />
                {children}
            </StyledContent>
        </Modal>
    );
}