import styled from 'styled-components';
import {CustomNavLink} from '../CustomLink/CustomLink';

const StyledNavBarLink = styled(CustomNavLink)`
    color: var(--color-secondary);
    font-family: var(--font-alt);
    font-weight: var(--fw-bold);
    font-size: var(--fs-navlink);
    line-height: 1.25;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    border: none;
    background-color: transparent;
    cursor: pointer; 
    
    &.active, &:active {
        color: var(--color-primary);
        
        svg {
            path { fill: var(--color-primary); }
        }
    }
    
    &:hover {
        background-color: var(--color-background);
    }
`;

export const NavBarLink = ({className, children, to, ...props}) => {
    return (
        <StyledNavBarLink className={className} to={to} {...props}>
            {children}
        </StyledNavBarLink>
    );
}