import styled from 'styled-components';
import {ReactComponent as Chevron} from '../../../assets/icons/chevron.svg';
import {Text} from '../../../components/Text/Text';
import {useState} from 'react';

const StyledAccordion = styled.div`
    border: 1px solid var(--color-border);
    border-radius: 4px;
    background-color: ${({$isOpen}) => $isOpen ? 'var(--color-accordion-active-background)' : 'var(--color-background)'};

    &:not(:last-of-type) {
        margin-bottom: 8px;
    }
`;

const Header = styled.div`
    padding: 24px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
    
    &:hover {
        background-color: var(--color-accordion-active-background);
    }
`;

const StyledChevron = styled(Chevron)`
    flex-shrink: 0;
    transition: transform .15s ease;
    
    ${({$isOpen}) => $isOpen && 'transform: rotate(90deg);'};
`;

const Content = styled.div`
    padding: 24px;
    border-top: 1px solid var(--color-border);
`;

const StyledText = styled(Text)`
    white-space: pre-wrap;
`;

export const Accordion = ({title, children, isActive, handleClick, isDefaultOpen, canOpenAll}) => {
    // If accordion canOpenAll, the local state and handle function are used instead of the lifted
    const [isActiveLocally, setIsActiveLocally] = useState(isDefaultOpen);

    const handleClickLocally = () => {
        setIsActiveLocally(!isActiveLocally)
    }

    const isOpen = canOpenAll ? isActiveLocally : isActive;

    return (
        <StyledAccordion $isOpen={isOpen}>
            <Header onClick={canOpenAll ? handleClickLocally : handleClick}>
                <Text $weight="bold" $noMargin>{title}</Text>
                <StyledChevron $isOpen={isOpen} />
            </Header>

            {isOpen && (
                <Content>
                    <StyledText $noMargin>{children}</StyledText>
                </Content>
            )}
        </StyledAccordion>
    );
}