import { register } from 'swiper/element/bundle';
import {useEffect, useRef} from 'react';
import styled from 'styled-components';

const StyledLoginSwiper = styled.section`
    flex: 1 2 1300px;
    overflow: hidden;
    width: 100%;
    height: 100vh;

    // swiper-container is a web component from Swiper Element
    swiper-container {
        height: 100%;
        
        &::part(bullet), &::part(bullet-active) {
            width: 14px;
            height: 14px;
            background: transparent;
            border: 3px solid var(--color-white);
            opacity: 1;
        }
        
        &::part(bullet-active) {
            background-color: var(--color-white);
        }
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
`;

const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const ColorOverlay = styled.div`
    position: absolute;
    inset: 0;
    background-color: var(--color-slider-overlay);
    pointer-events: none;
`;

export const LoginSwiper = ({images}) => {
    const swiperElRef = useRef(null);
    const autoPlayDelay = 5000;

    // Register Swiper web component
    useEffect(() => {
        register();
    }, []);

    return (
        <StyledLoginSwiper>
            <swiper-container
                ref={swiperElRef}
                pagination="true"
                pagination-clickable="true"
                loop="true"
                autoplay-delay={autoPlayDelay}
                autoplay-pause-on-mouse-enter="true"
            >
                {images?.map((item, index) => (
                    <swiper-slide key={`${index}-${item}`}>
                        <ImageWrapper>
                            <Image src={item} alt="" />
                            <ColorOverlay />
                        </ImageWrapper>
                    </swiper-slide>
                ))}
            </swiper-container>
        </StyledLoginSwiper>
    );
}