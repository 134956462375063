import {Outlet, ScrollRestoration, useLocation, useNavigation} from 'react-router-dom';
import nProgress from 'nprogress';
import {useEffect} from 'react';
import styled from 'styled-components';
import GlobalStyle from '../../globalStyles';
import {useWindowDimensions} from '../../hooks/useWindowDimensions';
import {PreventSmallScreens} from './Partials/PreventSmallScreens';

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Root = () => {
    const location = useLocation();
    const navigation = useNavigation();
    const {width} = useWindowDimensions();
    const screenIsTooSmall = width <= 1024;

    // NProgress bar (loadingbar on page change)
    nProgress.configure({
        showSpinner: false,
        template: '<div class="bar" role="bar"></div>',
        minimum: 0.3,
        speed: 400,
    });

    // Normal navigation in data router is idle -> loading -> idle, so start nProgress if state changed to "loading"
    if(navigation.state === "loading") {
        nProgress.start();
    }

    if(navigation.state === "idle") {
        nProgress.done();
    }

    // When the location changed, complete the nProgress
    useEffect(() => {
        nProgress.done();
    }, [location])

    return (
        <StyledLayout>
            <GlobalStyle />
            <ScrollRestoration />
            {screenIsTooSmall ? (
                <PreventSmallScreens />
            ) : (
                <Outlet />
            )}
        </StyledLayout>
    );
}

export default Root;

export const RootFallback = ({children}) => {
    return (
        <StyledLayout>
            <GlobalStyle />
            <ScrollRestoration />
            {children}
        </StyledLayout>
    );
}