import styled from 'styled-components';
import {Heading2} from '../../components/Heading/Heading';
import {textStyles} from '../../components/Text/Text';

const TableWrapper = styled.div`
    overflow: hidden;
`;

const Table = styled.div`
    td, tr {
        padding: 12px 24px;
        border: 1px solid var(--color-border);
        ${textStyles};
        margin-bottom: 0;
        overflow-wrap: unset;
    }
    
    // Unset the width that API passes
    tr, td {
        width: unset !important;
        height: unset !important;
    }
    
    table {
        thead {
            tr {
                td {
                    background-color: var(--color-table-header-background);
                    font-weight: var(--fw-bold);
                }
            }
        }
        tbody {
            tr {
                td {
                    background-color: var(--color-white);
                }
            }
        }
    }
`;

export const TableBlock = ({data}) => {
    return (
        <section>
            {data?.title && <Heading2>{data.title}</Heading2>}
            {data?.text && (
                <TableWrapper>
                    <Table
                        dangerouslySetInnerHTML={{__html: data.text}}
                    />
                </TableWrapper>
            )}
        </section>
    );
}