import styled, {css} from 'styled-components';
import {ReactComponent as ArrowLeft} from '../../assets/icons/arrow-left.svg';
import {ReactComponent as ArrowRight} from '../../assets/icons/arrow-right.svg';
import {CustomLink} from '../CustomLink/CustomLink';
import {Text} from '../Text/Text';

const StyledPageNavigation = styled.div`
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledCustomLink = styled(CustomLink)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 70px;
    text-decoration: none;
    
    &:first-of-type {
        border-right: 1px solid var(--color-border);
    }
    
    ${({$disabled}) => $disabled && css`
        pointer-events: none;
        opacity: .5;
    `};
`;

const Icon = styled.svg`
    width: 32px;
    height: 32px;
    
    ${StyledCustomLink}:hover & {
        path { fill: var(--color-primary); }
    }
`;

const NavLabel = styled(Text).attrs({
    $small: true,
    $noMargin: true
})`
    ${StyledCustomLink}:hover & {
        color: var(--color-primary);
    }
`;

export const PageNavigation = ({prevUrl, nextUrl}) => {
    return (
        <StyledPageNavigation>
            <StyledCustomLink to={prevUrl} $disabled={!prevUrl}>
                <Icon as={ArrowLeft} />
                <NavLabel>Vorige</NavLabel>
            </StyledCustomLink>

            <StyledCustomLink to={nextUrl} $disabled={!nextUrl}>
                <Icon as={ArrowRight} />
                <NavLabel>Volgende</NavLabel>
            </StyledCustomLink>
        </StyledPageNavigation>
    );
}