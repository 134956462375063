import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import Root, {RootFallback} from "./routes/Root/Root";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorBoundary from "./routes/Error/ErrorBoundary";
import Login, {loginLoader} from "./routes/Login/Login";
import Modules, {modulesLoader, modulesShouldRevalidate} from "./routes/Modules/Modules";
import LoggedInLayout from "./layouts/LoggedInLayout";
import ModuleDetail, {moduleDetailLoader} from "./routes/ModuleDetail/ModuleDetail";
import {RequireAuth} from "./routes/RouteProtectors/RequireAuth";
import {RequireGuest} from "./routes/RouteProtectors/RequireGuest";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <RootFallback />,
        children: [
            {
                element: <RequireGuest />,
                children: [
                    {
                        path: "/login",
                        loader: loginLoader,
                        element: <Login />,
                        errorElement: <ErrorBoundary />,
                    },
                ],
            },
            {
                element: <RequireAuth />,
                children: [
                    {
                        element: <LoggedInLayout />,
                        errorElement: <ErrorBoundary />,
                        children: [
                            {
                                index: true,
                                loader: modulesLoader,
                                shouldRevalidate: modulesShouldRevalidate,
                                element: <Modules />,
                                errorElement: <ErrorBoundary />,
                            },
                            {
                                path: "/*",
                                loader: moduleDetailLoader,
                                element: <ModuleDetail />,
                                errorElement: <ErrorBoundary />,
                            },
                        ],
                    },
                ],
            },
        ],
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        {/*<React.StrictMode>*/}
            <HelmetProvider>
                <RouterProvider router={router} />
            </HelmetProvider>
        {/*</React.StrictMode>*/}
    </>
);