import styled from 'styled-components';

export const Tag = styled.span`
    border-radius: 4px;
    padding: 4px 8px;
    background: var(--color-tag);
    font-family: var(--font-alt);
    font-size: var(--fs-tag);
    font-weight: var(--fw-bold);
    color: var(--color-white);
    line-height: 1.25;
`;