import {Navigate, Outlet, useLocation} from 'react-router-dom';
import Cookies from 'js-cookie';

export function RequireGuest() {
    const location = useLocation();

    if (Boolean(Cookies.get('da-vinci_token'))) {
        return <Navigate to={location.state?.from?.pathname || '/'} state={{ from: location }} replace />
    }

    return <Outlet />;
}