import styled from 'styled-components';
import {FooterText} from '../FooterText/FooterText';
import {Heading4} from '../Heading/Heading';
import {Text} from '../Text/Text';
import {CustomNavLink} from '../CustomLink/CustomLink';
import {useLocation} from 'react-router-dom';

const StyledPage = styled.div`
    display: flex;
`;

const SideBar = styled.div`
    flex: 0 1 400px;
    position: sticky;
    left: 0;
    top: 72px; // Should be the height of NavBar
    bottom: 0;
    align-self: flex-start;
    height: calc(100vh - 72px); // Should be the height of NavBar
    overflow-y: auto;
    background-color: var(--color-white);
    border-right: 1px solid var(--color-border);
    padding: 24px;
    z-index: 99;
`;

const Main = styled.div`
    flex: 1 1 1500px;
    min-height: calc(100vh - 72px); // Should be the height of NavBar
    display: flex;
    flex-direction: column;
    padding-bottom: 56px;
`;

const StyledFooterText = styled(FooterText)`
    margin-top: auto;
`;

const TableOfContents = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const TableOfContentsItemHolder = styled.div``;

const Chapter = styled(Text).attrs({
    as: CustomNavLink,
    $small: true,
    $weight: "bold",
    $noMargin: true,
    end: true,
})`
    text-decoration: none;
    
    &:hover {
        color: var(--color-primary);
    }
    
    &.active {
        color: var(--color-primary);
        text-decoration: underline;
        font-weight: var(--fw-bold);
    }
`;

const Paragraphs = styled.div`
    display: ${({$isOpen}) => $isOpen ? "flex" : "none"};
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    margin-left: 16px;
    
    // Remove margin-top from nested Paragraphs
    & > * { margin-top: 0; } 
`;

const Paragraph = styled(Chapter).attrs({
    $weight: "regular",
})``;

const TableOfContentsItem = ({ data, parent = true, currentParentUrl }) => {
    let isOpen;
    const urlParts = data?.url?.split('/');
    const url = urlParts.slice(0, 4).join('/') + '/';
    isOpen = currentParentUrl === url;

    return (
        <>
            {parent
                ? <Chapter to={data?.url}>{data?.title}</Chapter>
                : <Paragraph to={data?.url}>{data?.title}</Paragraph>
            }
            {data?.children?.length > 0 &&
                <Paragraphs $isOpen={isOpen}>
                    {data?.children?.map(child => (
                        <TableOfContentsItem
                            key={child.id}
                            data={child}
                            parent={false}
                            currentParentUrl={currentParentUrl}
                        />
                    ))}
                </Paragraphs>
            }
        </>
    );
};

Page.SideBar = function Page({children, data}) {
    const {pathname} = useLocation();
    const pathnameParts = pathname.split('/');
    const currentParentUrl = pathnameParts.slice(0, 4).join('/') + '/';

    return (
        <SideBar>
            <Heading4 $noMargin>Inhoudsopgave</Heading4>
            <TableOfContents>
                {data?.children.map(child => (
                    <TableOfContentsItemHolder key={child.id}>
                        <TableOfContentsItem data={child} currentParentUrl={currentParentUrl} />
                    </TableOfContentsItemHolder>
                ))}
                {children}
            </TableOfContents>
        </SideBar>
    );
}

Page.Main = function Page({children}) {
    return (
        <Main>
            {children}
            <StyledFooterText />
        </Main>
    );
}

export function Page({children, ...props}) {
    return (
        <StyledPage {...props}>
            {children}
        </StyledPage>
    );
}