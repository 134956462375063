import styled, {css} from 'styled-components';
import {HTMLText, Text} from '../../components/Text/Text';
import {Heading2} from '../../components/Heading/Heading';
import {Button} from '../../components/Button/Button';
import {useState} from 'react';

const Tabs = styled.div`
    display: flex;
    flex-wrap: nowrap;
    background-color: var(--color-tab-background);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow-x: auto;
`;

const Tab = styled.div`
    flex: 1 0 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 12px;

    ${({$isOpen}) => $isOpen ? css`
        border: 1px solid var(--color-border);
        border-bottom-color: transparent;
        background-color: var(--color-white);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    ` : css`
        border-bottom-color: var(--color-border);
    `};
    
    &:first-of-type {
        border-top-left-radius: 8px;
    }

    &:last-of-type {
        border-top-right-radius: 8px;
    }
`;

const Inner = styled.div`
    padding: 32px;
    background-color: var(--color-white);
    border: 1px solid var(--color-border);
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

const ButtonWrapper = styled.div`
    margin-top: 40px;
`;

export const TabBlock = ({data}) => {
    const [activeId, setActiveId] = useState(data?.tabs[0]?.id || 0);

    const handleClick = (id) => {
        setActiveId(id);
    }

    const currentData = data?.tabs?.find(item => item.id === activeId);
    const hasButton = (currentData?.linkLabel && currentData?.linkType && currentData?.link);

    return (
        <section>
            {data?.title && <Heading2>{data.title}</Heading2>}

            <Tabs>
                {data?.tabs?.map(item => (
                    <Tab
                        key={item.id}
                        $isOpen={activeId === item.id}
                        onClick={() => handleClick(item.id)}
                    >
                        <Text $weight="bold" $align="center" $noMargin $truncate $clamp={2}>{item.title}</Text>
                    </Tab>
                ))}
            </Tabs>
            <Inner>
                {currentData?.text && (
                    <HTMLText
                        dangerouslySetInnerHTML={{__html: currentData.text}}
                    />
                )}
                {hasButton && (
                    <ButtonWrapper>
                        <Button to={currentData.link} type={currentData.linkType}>
                            {currentData.linkLabel}
                        </Button>
                    </ButtonWrapper>
                )}
            </Inner>
        </section>
    );
}
